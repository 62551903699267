import React, { useState } from "react"
import ReactPlayer from "react-player"
import Img from "gatsby-image/withIEPolyfill"
import { useSpring, animated } from "react-spring"

import VideoPlaceholder from "./videoPlaceholder"

const Video = ({ videoSrcURL, videoThumbnail, isVisible }) => {
  const [springProps, setSpringProps] = useSpring(() => ({
    config: { duration: 50 },
    opacity: 0,
  }))

  const [videoPlaying, setVideoPlaying] = useState(false)

  const handlePlayPause = () => {
    setVideoPlaying(!videoPlaying)
  }

  const AnimatedReactPlayer = animated(ReactPlayer)

  if (!isVisible) {
    if (videoPlaying) {
      handlePlayPause()
    }
    setSpringProps({ opacity: 0 })
    return (
      <div className="w-full md:w-1/2 xl:w-1/3 px-2 mb-4">
        <VideoPlaceholder />
      </div>
    )
  } else {
    setSpringProps({ opacity: 1 })
    return (
      <div
        className={`${
          !videoPlaying && "video-container"
        } relative w-full md:w-1/2 xl:w-1/3 flex flex-col px-2 mb-4`}
      >
        <div style={{ paddingTop: "56.25%" }} className="relative">
          {!videoPlaying ? (
            <animated.div onClick={handlePlayPause} style={{ ...springProps }}>
              <Img
                className="absolute inset-0"
                style={{
                  height: "100%",
                  position: "absolute",
                  width: "100%",
                }}
                fluid={videoThumbnail}
                loading="eager"
              />
              <div className="absolute inset-0 flex justify-center items-center">
                <svg
                  className="h-12 w-12"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    fill="#FFF"
                    d="M15 10.001c0 .299-.305.514-.305.514l-8.561 5.303C5.51 16.227 5 15.924 5 15.149V4.852c0-.777.51-1.078 1.135-.67l8.561 5.305c-.001 0 .304.215.304.514z"
                  />
                </svg>
              </div>
            </animated.div>
          ) : (
            <AnimatedReactPlayer
              url={videoSrcURL}
              className="absolute inset-0"
              width="100%"
              height="100%"
              style={{ ...springProps }}
              playing={videoPlaying}
              onClick={handlePlayPause}
              onEnded={handlePlayPause}
            />
          )}
        </div>
      </div>
    )
  }
}

export default Video
