import React, { Fragment } from "react"
import { graphql } from "gatsby"
import VisibilitySensor from "react-visibility-sensor"
import { useSpring, animated } from "react-spring"

import SEO from "../components/seo"
import Video from "../components/video/video"

import metaImage from "../images/bri-stauss.jpg"

export const query = graphql`
  query VideoQuery {
    allYoutubeVideo(sort: { order: DESC, fields: publishedAt }) {
      edges {
        node {
          id
          title
          description
          videoId
          publishedAt
          privacyStatus
          channelTitle
          localThumbnail {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    allInstagram(
      sort: { fields: timestamp, order: DESC }
      filter: { media_type: { eq: "VIDEO" } }
    ) {
      edges {
        node {
          id
          media_type
          media_url
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`

export default ({
  data: {
    allYoutubeVideo: { edges: videos },
    allInstagram: { edges: posts },
  },
}) => {
  const springProps = useSpring({
    opacity: 1,
    from: { opacity: 0 },
  })

  return (
    <>
      <SEO
        title="Video"
        description="Videos of Bri Stauss"
        type="article"
        image={metaImage}
        pathname="video"
      />
      <animated.section
        className="p-4 pt-0"
        style={{ marginTop: "86px", ...springProps }}
      >
        <div className="flex flex-wrap -mx-2">
          {videos.map(({ node: video }) => {
            return (
              <Fragment key={video.id}>
                <VisibilitySensor
                  intervalDelay={100}
                  partialVisibility={true}
                  offset={{ top: -500, bottom: -500 }}
                >
                  {({ isVisible }) => {
                    return (
                      <Video
                        videoSrcURL={`https://www.youtube.com/watch?v=${video.videoId}`}
                        videoThumbnail={
                          video.localThumbnail.childImageSharp.fluid
                        }
                        isVisible={isVisible}
                      />
                    )
                  }}
                </VisibilitySensor>
              </Fragment>
            )
          })}
          {posts.map(({ node: post }) => {
            if (
              post.media_type === "VIDEO" &&
              post.media_url &&
              post.localFile
            ) {
              return (
                <Fragment key={post.id}>
                  <VisibilitySensor
                    intervalDelay={100}
                    partialVisibility={true}
                  >
                    {({ isVisible }) => {
                      return (
                        <Video
                          videoSrcURL={post.media_url}
                          videoThumbnail={post.localFile.childImageSharp.fluid}
                          isVisible={isVisible}
                        />
                      )
                    }}
                  </VisibilitySensor>
                </Fragment>
              )
            } else {
              return null
            }
          })}
        </div>
      </animated.section>
    </>
  )
}
