import React from "react"

const VideoPlaceholder = () => {
  return (
    <div
      style={{ paddingTop: "56.25%" }}
      className="relative w-full h-full overflow-hidden cursor-pointer"
    >
      <svg width="100%" height="100%" className="absolute inset-0">
        <rect
          width="100%"
          height="100%"
          style={{
            fill: "#eee",
            strokeWidth: 3,
          }}
        />
      </svg>
    </div>
  )
}

export default VideoPlaceholder
